import { mapActions } from 'vuex'

export default{
    
  data: () => ({
        collections: [],
        model: {},
        params: {},
        meta: {},
        showForm: false,
        loadingProgress: false,
        loading: false,
        headers: [
            { text: 'Código', value: 'code'},
            { text: 'Nome', value: 'name'},
            { text: 'Ações', value: 'actions'},
        ],
    }),
    
    watch: {
        
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        },

        model(item_new, item_old){
        
            if(item_new !== item_old && this.model.id)
            {
                this.showForm = true;
            } else if(Object.keys(this.model).length === 0){
                this.showForm = false;
            }           
        },
    
        showForm(show_new, show_old) {
            if(show_new != show_old && !show_new) {
                this.model = {};
            }
        },
      
    },
    components: {
      
    },
    mounted(){
       
    },
    created() {
      
    },
    methods: {
      
        ...mapActions('teacher', ['ActionFindTeachers']),

        getData(){
      
            this.loadingProgress = true;
    
            this.ActionFindTeachers(this.params)
                .then((res) => {
                    this.collections = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingProgress = false;
                })
        },

    }

}
